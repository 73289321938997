import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Magnifier from "../../shared/magnifier.svg";
import "./Search.scss";
import { ReactSVG } from "react-svg";
import { useForm } from "react-hook-form";

const Search = ({ params, setParams }) => {
  const [categories, setCategories] = useState([]);
  const history = useHistory();
  const { register, handleSubmit } = useForm();
  useEffect(() => {
    fetch("https://santeh-36.ru/api/categories")
      .then((res) => res.json())
      .then(
        (result) => {
          setCategories(result);
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  const onSubmit = (data) => {
    let reservedProps = {};
    let reservedMaterial = {};
    let reservedCountry = {};
    if (params) {
      reservedProps = params.reservedProps;
      reservedMaterial = params.reservedMaterial;
      reservedCountry = params.reservedCountry;
    }
    if (setParams) {
      setParams((prev) => {
        return {
          name: data.name,
          owner_id: data.category,
          reservedProps: prev.reservedProps,
          reservedMaterial: prev.reservedMaterial,
          reservedCountry: prev.reservedCountry,
        };
      });
    }
    history.push({
      pathname: `/shop/${data.category}`,
      state: {
        ...params,
        name: data.name,
        owner_id: data.category,
        reservedProps: reservedProps,
        reservedMaterial: reservedMaterial,
        reservedCountry: reservedCountry,
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={"search-form"}>
      <div className="select-block">
        <div className="select">
          <select
            {...register("category")}
            defaultValue={params?.owner_id || ""}
          >
            <option value="">Каталог</option>
            {categories.map((category, key) => (
              <option key={key} value={category._id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <input
            className="form-field"
            type="text"
            placeholder="Поиск"
            {...register("name")}
            defaultValue={params?.name}
          />
          <button className={"magnifier-span"}>
            <ReactSVG src={Magnifier} className="magnifier-button" />
          </button>
        </div>
      </div>
    </form>
  );
};

export default Search;
