import React from "react";
import "./PropsTable.scss";

const PropsTable = ({ prod }) => {
  return (
    <>
      {prod.props && (
        <>
          <h2>Характеристики</h2>
          <div className={"props-table"}>
            {prod.props?.map((prop, key) => (
              <React.Fragment key={key}>
                <div className={"props-section"}>
                  {prop.name}
                </div>
                <div className={"props-section"}>
                  {prop.value}
                </div>
              </React.Fragment>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default PropsTable;
