import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Img } from "react-image";
import Cart from "../../shared/cart-small.svg";
import "./Carousel.scss";
import { ReactSVG } from "react-svg";
import Check from "../../shared/check-mark.svg";

const Carousel = () => {
  const [shift, setShift] = useState(0);

  const [hotCustoms, setHotCustoms] = useState([]);
  const [cart, setCart] = useState([]);

  useEffect(() => {
    fetch("https://santeh-36.ru/api/goods/hot")
      .then((res) => res.json())
      .then(
        (result) => {
          setHotCustoms(result);
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  const swipeLeft = () => {
    setShift((prevShift) =>
      prevShift > 0 ? prevShift - 1 : hotCustoms.length - 1
    );
  };

  const swipeRight = () => {
    setShift((prevShift) =>
      prevShift < hotCustoms.length ? prevShift + 1 : 1
    );
  };

  const truncate = (str, n) => {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  const makeSlice = (arr) => {
    return arr.length < 3
      ? arr
      : shift >= arr.length - 2
      ? arr.slice(shift).concat(arr.slice(0, 3 - (arr.length - shift)))
      : arr.slice(shift, shift + 3);
  };

  useEffect(() => {
    if (!sessionStorage.cart) {
      sessionStorage.cart = "[]";
    }
    const cart = JSON.parse(sessionStorage.cart);
    setCart(cart);
  }, []);

  const addToCart = (id) => {
    if (!sessionStorage.cart) {
      sessionStorage.cart = "[]";
    }
    const cart = JSON.parse(sessionStorage.cart);
    if (!cart.includes(id)) {
      cart.push(id);
    }
    sessionStorage.cart = JSON.stringify(cart);
    setCart(cart);
  };

  return (
    <>
      {hotCustoms.length > 3 && (
        <>
          <h2 className={"carousel-header"}>Популярные товары</h2>
          <div className={"carousel-container"}>
            {makeSlice(hotCustoms).map((item, key) => (
              <div className={"carousel-item-container"} key={key}>
                <Link className={"carousel-item"} to={`/product/${item._id}`}>
                  <Img
                    src={`data:image/jpeg;base64,${item.cover}`}
                    className={"carousel-image"}
                  />
                  <p className={"carousel-title"}>{truncate(item.name, 60)}</p>
                  <p className={"carousel-price"}>
                    {item.price} руб./{item.price_type}
                  </p>
                </Link>
                {!cart?.includes(item._id) ? (
                  <ReactSVG
                    src={Cart}
                    className={"carousel-cart"}
                    onClick={() => addToCart(item._id)}
                  />
                ) : (
                  <ReactSVG src={Check} className={"carousel-bought"} />
                )}
              </div>
            ))}
            <div
              onClick={swipeLeft}
              className={"carousel-arrow carousel-arrow-left"}
            >
              <div className={"carousel-arrow-left-container"}>&nbsp;</div>
            </div>
            <div
              onClick={swipeRight}
              className={"carousel-arrow carousel-arrow-right"}
            >
              <div className={"carousel-arrow-right-container"}>&nbsp;</div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Carousel;
