import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import ShopCards from "../../components/ShopCards/ShopCards";
import Search from "../../components/Search/Search";
import CategoriesLabels from "../../components/CategoriesLabels/CategoriesLabels.jsx";
import "./Shop.scss";
import SidePanel from "../../components/SidePanel/SidePanel.jsx";
import ShopTiles from "../../components/ShopTiles/ShopTiles";
import SubCategoriesTiles from "../../components/SubCategoriesTiles/CategoriesTiles";

const Shop = ({ match }) => {
  const [showCards, setShowCards] = useState(true);
  const [showSortByPrice, setShowSortByPrice] = useState(null);
  const [showSortByName, setShowSortByName] = useState(null);
  const location = useLocation();
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [cnt, setCnt] = useState(0);
  const [owner, setOwner] = useState(0);
  const [params, setParams] = useState({});
  const history = useHistory();
  const switchCards = () => {
    setShowCards((prev) => !prev);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const switchSortByPrice = () => {
    setParams((prev) => {
      let newParams = prev;
      if (showSortByPrice) {
        newParams.sortByPrice = true;
        newParams.sortByPriceDesc = false;
        newParams.sortByName = false;
        newParams.sortByNameDesc = false;
      } else {
        newParams.sortByPriceDesc = true;
        newParams.sortByPrice = false;
        newParams.sortByName = false;
        newParams.sortByNameDesc = false;
      }
      return newParams;
    });
    setShowSortByPrice((prev) => (prev ? !prev : true));
    history.push({
      state: params,
    });
  };

  const switchSortByName = () => {
    setParams((prev) => {
      let newParams = prev;
      if (showSortByName) {
        newParams.sortByPrice = false;
        newParams.sortByPriceDesc = false;
        newParams.sortByName = true;
        newParams.sortByNameDesc = false;
      } else {
        newParams.sortByPrice = false;
        newParams.sortByPriceDesc = false;
        newParams.sortByNameDesc = true;
        newParams.sortByName = false;
      }
      return newParams;
    });
    setShowSortByName((prev) => (prev ? !prev : true));
    history.push({
      state: params,
    });
  };

  useEffect(() => {
    if (location.state) {
      setName(location.state.name);
    }
  }, [location]);

  let categoryId = match.params.id;
  useEffect(() => {
    setParams(
      Object.assign(
        {},
        location.state,
        categoryId && { owner_id: categoryId },
        name && { name: name }
      )
    );

    fetch(
      `https://santeh-36.ru/api/categories/${
        categoryId !== "undefined" ? (categoryId ? categoryId : "") : ""
      }`
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setCategory(result?.name);
          setCnt(result?.goods?.length);
          setOwner(result?._id);
        },
        (error) => {
          console.log(error);
        }
      );
  }, [categoryId, name, location.state]);

  return (
    <>
      <Search params={params} setParams={setParams} />
      <CategoriesLabels selected={category} params={params} />
      <SubCategoriesTiles parentId={categoryId} />
      {(!category || cnt > 0) && <div className={"shop-container"}>
        <SidePanel params={params} setParams={setParams} owner={owner} />
        <div className={"shop-container-cards"}>
          <div className={"shop-container-header"}>
            <section id={"scroll-top"}>
              <h2>{category ? category : "Все товары"}</h2>
            </section>
            <div className={"shop-container-switches"}>
              <div
                onClick={() => switchSortByPrice()}
                className={"shop-container-switch"}
              >
                {showSortByPrice === null
                  ? "По цене"
                  : !showSortByPrice
                  ? "По возрастанию цены"
                  : "По убыванию цены"}
              </div>
              <div
                onClick={() => switchSortByName()}
                className={"shop-container-switch"}
              >
                {showSortByName === null
                  ? "По алфавиту"
                  : !showSortByName
                  ? "По алфавиту А-Я"
                  : "По алфавиту Я-А"}
              </div>
              <div
                onClick={() => switchCards()}
                className={"shop-container-switch shop-style-switch"}
              >
                {showCards ? "Плитками" : "Карточками"}
              </div>
            </div>
          </div>
          {!showCards ? (
            <ShopTiles maxCount={9} params={params} />
          ) : (
            <ShopCards maxCount={15} params={params} />
          )}
        </div>
      </div>}
    </>
  );
};

export default Shop;
