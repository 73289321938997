import React from "react";
import "./Goods.scss";
import {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {useCookies} from "react-cookie";
import {useForm} from "react-hook-form";

const AdminGoods = ({match}) => {
    const history = useHistory();
    const [category, setCategory] = useState({});
    const [goods, setGoods] = useState([]);
    const [cookies] = useCookies(["token"]);
    const [categories, setCategories] = useState([]);
    const [editableId, setEditableId] = useState(-1);

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm();

    const {
        register: register2,
        handleSubmit: handleSubmit2,
        formState: {errors: errors2},
    } = useForm();
    const onSubmit = (data) => {
        fetch("https://santeh-36.ru/api/categories", {
            body: JSON.stringify({parentId: match.params.id, ...data}),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + cookies["token"],
            },
            method: "POST",
        })
            .then((res) => {
                return res.json();
            })
            .then(
                (result) => {
                    window.location.reload();
                },
                (error) => {
                    console.log(error);
                }
            );
    };

    const handleSetInEditMode = (_id) => {
        setEditableId(_id);
    };

    const onUpdateCategory = (data) => {
        fetch("https://santeh-36.ru/api/categories", {
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + cookies["token"],
            },
            method: "PUT",
        })
            .then((res) => {
                return res.json();
            })
            .then(
                (result) => {
                    window.location.reload();
                },
                (error) => {
                    console.log(error);
                }
            );
    };

    useEffect(() => {
        fetch("https://santeh-36.ru/api/admin", {
            headers: {
                Authorization: "Bearer " + cookies["token"],
            },
        })
            .then((res) => res.json())
            .then(
                (result) => {
                    if (!result) {
                        history.push("/login");
                    }
                },
                (error) => {
                    history.push("/login");
                }
            );
    }, [history, cookies]);

    useEffect(() => {
        fetch("https://santeh-36.ru/api/categories/" + match.params.id)
            .then((res) => res.json())
            .then(
                (result) => {
                    setCategory(result);
                    fetch(
                        "https://santeh-36.ru/api/goods/filter?owner_id=" + match.params.id
                    )
                        .then((res) => res.json())
                        .then(
                            (result) => {
                                setGoods(result);
                            },
                            (error) => {
                                console.log(error);
                            }
                        );
                },
                (error) => {
                    console.log(error);
                }
            );
        if (match.params.id) {
            fetch(
                `https://santeh-36.ru/api/categories/subcategories/${match.params.id}`
            )
                .then((res) => res.json())
                .then(
                    (result) => {
                        setCategories(result);
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        }
    }, [match.params.id]);


    function declOfNum(n, titles) {
        return titles[
            n % 10 === 1 && n % 100 !== 11
                ? 0
                : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
                ? 1
                : 2
            ];
    }

    const handleDeleteCategory = (_id, cnt) => {
        if (cnt === 0) {
            fetch("https://santeh-36.ru/api/categories/" + _id, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + cookies["token"],
                },
                method: "Delete",
            })
                .then((res) => {
                    return res.json();
                })
                .then(
                    (result) => {
                        window.location.reload();
                    },
                    (error) => {
                        console.log(error);
                    }
                );
        }
        else {
            window.alert('Невозможно удалить категорию с товарами!');
        }
    };

    return (
        <>
            {category && <h3>Категория: {category.name}</h3>}
            {category.parentId ? <><Link
                to={"/admin/categories/" + category.parentId}>Назад</Link><br/><br/><br/></> : <><Link
                to={"/admin/categories"}>Назад к категориям</Link><br/><br/><br/></>}
            <Link to={"/admin/goods/-1/" + category._id} className={"admin-goods-button"}>Добавить
                товар</Link><br/><br/><br/>

            <h1>Добавление новой подкатегории</h1>
            <form onSubmit={handleSubmit(onSubmit)} key={"add_category"}>
                <div className={"login-form-container"}>
                    <label>
                        Название категории
                        <input {...register("name", {required: true})} />
                    </label>
                    {errors.name && (
                        <p className={"login-form-error"}>
                            Необходимо ввести название категории.
                        </p>
                    )}
                    <input type="submit"/>
                </div>
            </form>
            {categories.length > 0 && <><h1>Редактирование подкатегорий</h1>
                <div className="admin-categories-labels-container">
                    {[
                        {
                            _id: "",
                            name: "Все товары",
                            count_of_goods: categories.reduce(
                                (cnt, cat) => cnt + (cat.count_of_goods || 0),
                                0
                            ),
                        },
                        ...categories,
                    ].map((category, key) => (
                        <React.Fragment key={key}>
                            {category.name !== "Все товары" && (
                                <div className={"admin-categories-labels-item"}>
                                    {category._id === editableId ? (
                                        <form onSubmit={handleSubmit2(onUpdateCategory)} key={key}>
                                            <label>
                                                {category.name}
                                                <input {...register2("name", {required: true})} />
                                            </label>
                                            {errors2.name && (
                                                <p className={"login-form-error"}>
                                                    Необходимо ввести название категории.
                                                </p>
                                            )}
                                            <input
                                                {...register2("id", {required: true})}
                                                defaultValue={category._id}
                                                type={"hidden"}
                                            />
                                            <input
                                                {...register2("count_of_goods", {required: true})}
                                                defaultValue={category.count_of_goods}
                                                type={"hidden"}
                                            />
                                            <p className={"category-labels-count"}>
                                                {category.count_of_goods}{" "}
                                                {declOfNum(category.count_of_goods, [
                                                    "товар",
                                                    "товара",
                                                    "товаров",
                                                ])}
                                            </p>
                                            <input type="submit"/>
                                            <button
                                                type={"button"}
                                                onClick={() => {
                                                    handleSetInEditMode(-1);
                                                }}
                                            >
                                                Отменить редактирование
                                            </button>
                                            <button
                                                type={"button"}
                                                onClick={() => {
                                                    handleDeleteCategory(category._id, category.count_of_goods);
                                                }}
                                            >
                                                Удалить
                                            </button>
                                            <Link to={"/admin/categories/" + category._id}>
                                                <button>К товарам в данной категории</button>
                                            </Link>
                                        </form>
                                    ) : (
                                        <>
                                            <h4 className={"category-labels-name"}>{category.name}</h4>
                                            <p className={"category-labels-count"}>
                                                {category.count_of_goods}{" "}
                                                {declOfNum(category.count_of_goods, [
                                                    "товар",
                                                    "товара",
                                                    "товаров",
                                                ])}
                                            </p>
                                            <button
                                                onClick={() => {
                                                    handleSetInEditMode(category._id);
                                                }}
                                            >
                                                Редактировать
                                            </button>
                                            <button
                                                onClick={() => {
                                                    handleDeleteCategory(category._id, category.count_of_goods);
                                                }}
                                            >
                                                Удалить
                                            </button>
                                            <Link to={"/admin/categories/" + category._id}>
                                                <button>К товарам в данной категории</button>
                                            </Link>
                                        </>
                                    )}
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </>}

            <h1>Редактирование товаров</h1>
            {goods?.map((good, key) => (
                <div key={key} className={"admin-categories-labels-item"}>
                    <div>{good.name && "Название: " + good.name}</div>
                    <div>
                        {good.size && (
                            <>
                                Размер: {good.size.width}
                                {good.size.height && "x" + good.size.height}
                                {good.size.depth && "x" + good.size.depth}
                                {good.size.size_type && " " + good.size.size_type}
                            </>
                        )}
                    </div>
                    <div>{good.country && "Страна: " + good.country}</div>
                    <div>{good.bar_code && "Артикул: " + good.bar_code}</div>
                    <div>
                        {good.price && (
                            <>
                                {good.price} руб.{good.price_type && `/${good.price_type}`}
                            </>
                        )}
                    </div>
                    <div>{good.material && "Материал: " + good.material}</div>
                    <div>{good.description && "Описание: " + good.description}</div>
                    <div>
                        {good?.props && <>Свойства</>}
                        {good?.props?.map((prop, key) => (
                            <div key={key}>
                                {prop.name}: {prop.value}
                            </div>
                        ))}
                    </div>
                    <div>Является ли популярным: {good.isHot ? "Да" : "Нет"}</div>
                    <Link to={"/admin/goods/" + good._id}>
                        <button>Редактировать товар</button>
                    </Link>
                </div>
            ))}
        </>
    );
};

export default AdminGoods;
