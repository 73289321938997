import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
// import { cartItems } from "../../placeholder/placeholder";
import { Img } from "react-image";
import "./Cart.scss";
import Cross from "../../shared/cross.svg";
import { ReactSVG } from "react-svg";

const Cart = () => {
  const [items, setItems] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    data.items = items.map(item => item._id);
    if (data.items.length === 0) {
      window.alert("Ваша корзина пуста. Добавьте товары и повторите попытку!");
      return;
    }
    fetch("https://santeh-36.ru/api/goods/cart", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          window.alert("Ваш заказ успешно принят. Наш менеджер скоро свяжется с Вами!")
          sessionStorage.cart = "[]";
          window.location.reload();
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!sessionStorage.cart) {
      sessionStorage.cart = "[]";
    }
    const cart = JSON.parse(sessionStorage.cart);
    if (cart.length > 0) {
      fetch(
        `https://santeh-36.ru/api/goods/filter?ids=${encodeURIComponent(
          cart.join("\\\\")
        )}`
      )
        .then((res) => res.json())
        .then(
          (result) => {
            setItems(result);
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }, []);

  const removeFromCart = (id) => {
    if (!sessionStorage.cart) {
      sessionStorage.cart = "[]";
    }
    let cart = JSON.parse(sessionStorage.cart);
    if (cart.includes(id)) {
      cart = cart.filter((value) => value !== id);
    }
    sessionStorage.cart = JSON.stringify(cart);
    setItems((prev) => prev.filter((item) => item._id !== id));
  };

  return (
    <>
      <h1>Корзина</h1>
      <div className={"cart-items-container"}>
        {items.map((item, key) => (
          <div className={"cart-item-container"} key={key}>
            <Link className={"cart-item"} to={`/product/${item._id}`}>
              <Img
                src={`data:image/jpeg;base64,${item.cover}`}
                className={"cart-image"}
              />
              <p className={"cart-title"}>{truncate(item.name, 60)}</p>
              <p className={"cart-price"}>
                {item.price} руб./{item.price_type}
              </p>
            </Link>
            <ReactSVG
              src={Cross}
              className={"cart-remove"}
              onClick={() => removeFromCart(item._id)}
            />
          </div>
        ))}
      </div>
      <h1 className={"cart-header"}>Уточнение деталей заказа</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={"cart-form"}>
          <div className={"cart-form-input-container"}>
            <input
              className={"cart-form-input"}
              placeholder={"Имя"}
              {...register("name", { required: true })}
            />
            {errors.name?.type === "required" && (
              <p className={"cart-error"}>Необходимо ввести имя</p>
            )}
          </div>
          <div className={"cart-form-input-container"}>
            <input
              className={"cart-form-input"}
              placeholder={"Фамилия"}
              {...register("surname", { required: true })}
            />
            {errors.surname?.type === "required" && (
              <p className={"cart-error"}>Необходимо ввести фамилию</p>
            )}
          </div>
          <div className={"cart-form-input-container"}>
            <input
              className={"cart-form-input"}
              type={"email"}
              placeholder={"Email"}
              {...register("email", { required: true })}
            />
            {errors.email?.type === "required" && (
              <p className={"cart-error"}>Необходимо ввести Email для связи</p>
            )}
            {errors.email?.type === "pattern" && (
              <p className={"cart-error"}>Неверный формат Email</p>
            )}
          </div>
          <div className={"cart-form-input-container"}>
            <input
              className={"cart-form-input"}
              placeholder={"Телефон"}
              name={"phone"}
              {...register("phone", {
                required: true,
                pattern: /^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/,
              })}
            />
            {errors.phone?.type === "required" && (
              <p className={"cart-error"}>
                Необходимо ввести телефон для связи
              </p>
            )}
            {errors.phone?.type === "pattern" && (
              <p className={"cart-error"}>Неверный формат телефона</p>
            )}
          </div>
          <div className={"cart-form-submit"}>
            <button className={"cart-form-button"} type={"submit"}>
              Подтвердить заказ
            </button>
            <p className={"cart-form-alert"}>
              * После подтверждения заказа с Вами свяжется наш менеджер для
              уточнения деталей
            </p>
          </div>
        </div>
      </form>
    </>
  );
};

export default Cart;
