import React, { useEffect, useState } from "react";
import {Link} from "react-router-dom";
import "./CategoriesTiles.scss";

const CategoriesTiles = () => {
  const [categories, setCategories] = useState([]);
  function declOfNum(n, titles) {
    return titles[
      n % 10 === 1 && n % 100 !== 11
        ? 0
        : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
        ? 1
        : 2
    ];
  }

  useEffect(() => {
    fetch("https://santeh-36.ru/api/categories")
      .then((res) => res.json())
      .then(
        (result) => {
          setCategories(result);
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);
  return (
    <>
      <h2>Категории</h2>
      <div className="categories-tiles-container">
        {categories.map((category, key) => (
          <Link
            className={"category-tiles-item"}
            key={key}
            to={`/shop/${category._id}`}
          >
            <h4 className={"category-tiles-name"}>{category.name}</h4>
            <p className={"category-tiles-count"}>
              {category.count_of_goods}{" "}
              {declOfNum(category.count_of_goods, ["товар", "товара", "товаров"])}
            </p>
          </Link>
        ))}
      </div>
    </>
  );
};

export default CategoriesTiles;
