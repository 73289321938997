import React, {useEffect, useState} from "react";
import "./ProductCard.scss";
import { ReactSVG } from "react-svg";
import Cart from "../../shared/cart.svg";
import Check from "../../shared/check-mark.svg";

const ProductCard = ({ prod }) => {
  const [cart, setCart] = useState([]);
  const truncate = (str, n) => {
    return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  };

  useEffect(() => {
    if (!sessionStorage.cart) {
      sessionStorage.cart = "[]";
    }
    const cart = JSON.parse(sessionStorage.cart);
    setCart(cart);
  }, []);

  const addToCart = (id) => {
    if (!sessionStorage.cart) {
      sessionStorage.cart = "[]";
    }
    const cart = JSON.parse(sessionStorage.cart);
    if (!cart.includes(id)) {
      cart.push(id);
    }
    sessionStorage.cart = JSON.stringify(cart);
    setCart(cart);
  };

  return (
    <div className={"product-card"}>
      <div className={"product-card-desc"}>
        <div>
          <h2>
            {prod.price} руб./{prod.price_type || "шт."}
          </h2>
          <p className={"product-card-vendor-code"}>
            {prod.bar_code && `Артикул: ${prod.bar_code}`}
          </p>
          {prod.country && (
            <div className={"product-card-section"}>
              <p className={"product-card-section-category"}>Страна:</p>
              <p className={"product-card-section-desc"} title={prod.country}>
                {truncate(prod.country, 30)}
              </p>
            </div>
          )}
          {prod.material && (
            <div className={"product-card-section"}>
              <p className={"product-card-section-category"}>Материал:</p>
              <p className={"product-card-section-desc"} title={prod.material}>
                {truncate(prod.material, 30)}
              </p>
            </div>
          )}
          {prod.size && (
            <div className={"product-card-section"}>
              <p className={"product-card-section-category"}>Размер:</p>
              <p className={"product-card-section-desc"}>
                {prod.size.width}
                {prod.size.height && "x" + prod.size.height}
                {prod.size.depth && "x" + prod.size.depth}
                {prod.size.size_type && " " + prod.size.size_type}
              </p>
            </div>
          )}
          {prod.props && (
            <>
              <h4>Характеристики</h4>
              {prod.props.slice(0, 5).map((prop, key) => (
                <div className={"product-card-section"} key={key}>
                  <p className={"product-card-section-category"} title={prop.name}>
                    {truncate(prop.name, 20)}
                  </p>
                  <p className={"product-card-section-desc"} title={prop.value}>
                    {truncate(prop.value, 20)}
                  </p>
                </div>
              ))}
            </>
          )}
        </div>
        {!cart?.includes(prod._id) ? (
          <button
            onClick={() => addToCart(prod._id)}
            className={"product-cart"}
          >
            <ReactSVG src={Cart} className={"product-cart-icon"} />
            {""}
            <p className={"product-cart-title"}>Добавить в корзину</p>
          </button>
        ) : (
          <button className={"product-cart"}>
            <ReactSVG src={Check} className={"product-cart-icon"} />
            {""}
            <p className={"product-cart-title"}>В корзине</p>
          </button>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
