import React, { useEffect, useState } from "react";
import "./Good.scss";
import { Link, useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import { Img } from "react-image";

const AdminGood = ({ match }) => {
  const history = useHistory();
  const [good, setGood] = useState([]);
  const [cookies] = useCookies(["token"]);
  const [props, setProps] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    data.props = props;
    data.owner_id = match.params.category;

    fetch("https://santeh-36.ru/api/goods", {
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + cookies["token"],
      },
      method: match.params.id !== '-1' ? "PUT" : "POST",
    })
      .then((res) => {
        return res.json();
      })
      .then(
        (result) => {
          if (match.params.id === '-1') {
            window.location.replace("/admin/goods/" + result._id);
          }
          window.location.reload();
        },
        (error) => {
          console.log(error);
        }
      );
  };

  useEffect(() => {
    fetch("https://santeh-36.ru/api/admin", {
      headers: {
        Authorization: "Bearer " + cookies["token"],
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (!result) {
            history.push("/login");
          }
        },
        (error) => {
          history.push("/login");
        }
      );
  }, [history, cookies]);

  useEffect(() => {
    if (match.params.id !== '-1') {
      fetch("https://santeh-36.ru/api/goods/" + match.params.id)
        .then((res) => res.json())
        .then(
          (result) => {
            const defaults = {
              id: result._id,
              name: result.name,
              width: result.size?.width,
              height: result.size?.height,
              depth: result.size?.depth,
              size_type: result.size?.size_type,
              country: result.country,
              bar_code: result.bar_code,
              price: result.price,
              price_type: result.price_type,
              material: result.material,
              description: result.description,
              props: result.props,
              isHot: result.isHot,
              owner: result.owner,
              cover: result.cover,
              images: result.images,
            };
            reset(defaults);
            setGood(result);
            setProps(defaults.props);
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }, [match.params.id, reset]);

  const handlePropNameChange = (e, key) => {
    setProps((prev) =>
      prev.map((p, k) =>
        k === key ? { _id: p._id, name: e.target.value, value: p.value } : p
      )
    );
  };

  const handlePropValueChange = (e, key) => {
    setProps((prev) =>
      prev.map((p, k) =>
        k === key ? { _id: p._id, name: p.name, value: e.target.value } : p
      )
    );
  };

  const handleAddProp = () => {
    setProps((prev) => [...prev, { _id: Date.now().toString() }]);
  };

  const handleDeleteProp = (key) => {
    setProps((prev) => {
      return prev.filter(
        (p) =>
          !(!(p.name + p.value !== key) || (isNaN(key) && !p.name && !p.value))
      );
    });
  };

  const handleCoverChange = (e) => {
    e.preventDefault();
    if (
      window.confirm(
        "Обратите внимание! Обложка будет заменена и страница будет перезагружена!"
      )
    ) {
      const data = new FormData();
      data.append("cover", e.target.files[0]);
      fetch("https://santeh-36.ru/api/goods/cover/" + good._id, {
        body: data,
        headers: {
          Authorization: "Bearer " + cookies["token"],
        },
        method: "PUT",
      })
        .then((res) => {
          return res.json();
        })
        .then(
          (result) => {
            window.location.reload();
          },
          (error) => {
            console.log(error);
          }
        );
    }
  };

  const handleImageAdd = (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append("image", e.target.files[0]);
    fetch("https://santeh-36.ru/api/goods/image/" + good._id, {
      body: data,
      headers: {
        Authorization: "Bearer " + cookies["token"],
      },
      method: "PUT",
    })
      .then((res) => {
        return res.json();
      })
      .then(
        (result) => {
          window.location.reload();
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleImageDelete = (key) => {
    fetch("https://santeh-36.ru/api/goods/image/" + good._id, {
      body: { image: key },
      headers: {
        Authorization: "Bearer " + cookies["token"],
      },
      method: "DELETE",
    })
      .then((res) => {
        return res.json();
      })
      .then(
        (result) => {
          window.location.reload();
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleCoverDelete = () => {
    fetch("https://santeh-36.ru/api/goods/cover/" + good._id, {
      headers: {
        Authorization: "Bearer " + cookies["token"],
      },
      method: "DELETE",
    })
      .then((res) => {
        return res.json();
      })
      .then(
        (result) => {
          window.location.reload();
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const deleteGood = () => {
    fetch("https://santeh-36.ru/api/goods/" + good._id, {
      headers: {
        Authorization: "Bearer " + cookies["token"],
      },
      method: "DELETE",
    })
      .then((res) => {
        return res.json();
      })
      .then(
        (result) => {
          window.location.replace('/admin/categories/' + (good.owner ? good.owner : match.params.category));
        },
        (error) => {
          console.log(error);
        }
      );
  };

  return (
    <>
      <Link to={"/admin/categories/" + (good.owner ? good.owner : match.params.category)}>Назад к категории</Link>
      {match.params.id !== '-1' && (
        <>
          <h3>Обложка</h3>
          <div className={"admin-image-container"}>
            {good.cover && (
              <>
                <Img
                  src={`data:image/jpeg;base64,${good.cover}`}
                  className={"admin-photo"}
                />
                <span className="admin-image-cross" onClick={handleCoverDelete}>
                  x
                </span>
              </>
            )}
            <label>
              Заменить обложку
              <input
                name="file"
                type={"file"}
                onChange={handleCoverChange}
                className={"file-picker"}
                accept="image/png, image/jpeg"
              />
            </label>
          </div>
          <h3>Фотографии</h3>
          <div className={"admin-photos"}>
            {good?.images?.map((image, key) => (
              <div className={"admin-image-container"} key={key}>
                <Img
                  src={`data:image/jpeg;base64,${image}`}
                  className={"admin-photo"}
                />
                <span
                  className="admin-image-cross"
                  onClick={(key) => handleImageDelete(key)}
                >
                  x
                </span>
              </div>
            ))}
            <label>
              Добавить фотографию
              <input
                name="file"
                type={"file"}
                onChange={handleImageAdd}
                className={"file-picker"}
                accept="image/png, image/jpeg"
              />
            </label>
          </div>
        </>
      )}
      <form onSubmit={handleSubmit(onSubmit)} key={"add_category"}>
        <div className={"admin-categories-labels-item admin-goods-item"}>
          <label>
            Название
            <input {...register("name", { required: true })} />
          </label>
          {errors.name && (
            <p className={"login-form-error"}>Необходимо ввести название.</p>
          )}
          <label>
            Ширина
            <input {...register("width")} />
          </label>
          <label>
            Высота
            <input {...register("height")} />
          </label>
          <label>
            Глубина
            <input {...register("depth")} />
          </label>
          <label>
            Тип размера (м/см/мм)
            <input {...register("size_type")} />
          </label>
          <label>
            Страна
            <input {...register("country")} />
          </label>
          <label>
            Артикул
            <input {...register("bar_code")} />
          </label>
          <label>
            Цена
            <input {...register("price")} />
          </label>
          <label>
            Тип цены (шт/кг/г)
            <input {...register("price_type")} />
          </label>
          <label>
            Материал
            <input {...register("material")} />
          </label>
          <label>
            Описание
            <textarea {...register("description")} />
          </label>
          <div>
            {props?.map((prop, key) => (
              <label key={key + prop._id}>
                Название свойства
                <input
                  onChange={(e) => handlePropNameChange(e, key)}
                  defaultValue={prop.name}
                />
                Значение свойства
                <input
                  onChange={(e) => handlePropValueChange(e, key)}
                  defaultValue={prop.value}
                />
                <button
                  type={"button"}
                  onClick={() => handleDeleteProp(prop.name + prop.value)}
                >
                  Удалить свойство
                </button>
              </label>
            ))}
            <button type={"button"} onClick={() => handleAddProp()}>
              Добавить свойство
            </button>
          </div>
          <label>
            Является ли популярным
            <input type={"checkbox"} {...register("isHot")} />
          </label>
          <input type="submit" />
          <button type={"button"} onClick={() => deleteGood()}>
            Удалить
          </button>
        </div>
      </form>
    </>
  );
};

export default AdminGood;
