import React from "react";
import "./ImageCarousel.scss";
import ImageGallery from "react-image-gallery";

const ImageCarousel = ({ prod }) => {
  let images = [prod.cover, ...prod.images].map((img) => {
    return {
      original: `data:image/jpeg;base64,${img}`,
      thumbnail: `data:ima0.0.ge/jpeg;base64,${img}`,
      originalClass: "product-carousel-image",
      thumbnailHeight: 80,
      thumbnailWidth: 80,
      originalHeight: 425,
      originalWidth: 425,
    };
  });
  return (
    <>
      <ImageGallery
        items={images}
        showPlayButton={false}
        showFullscreenButton={false}
        showNav={false}
        showThumbnails={((prod.cover ? 1 : 0) + prod.images?.length) > 1}
        additionalClass={"product-carousel-container"}
      />
    </>
  );
};

export default ImageCarousel;
