import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./CategoriesLabels.scss";

const CategoriesLabels = ({params}) => {
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  function declOfNum(n, titles) {
    return titles[
      n % 10 === 1 && n % 100 !== 11
        ? 0
        : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
        ? 1
        : 2
    ];
  }

  const handleClick = (_id) => {
    history.push({
        pathname: `/shop/${_id}`,
        state: {...params, owner_id: _id}
    });
  };

  useEffect(() => {
    fetch("https://santeh-36.ru/api/categories")
      .then((res) => res.json())
      .then(
        (result) => {
          setCategories(result);
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  return (
    <>
      <div className="categories-labels-container">
        {[{_id: '', name: "Все товары", count_of_goods: categories.reduce((cnt, cat) => cnt + (cat.count_of_goods || 0), 0)}, ...categories].map((category, key) => (
          <div
            className={"category-labels-item"}
            key={key}
            onClick={() => handleClick(category._id)}
          >
            <h4 className={"category-labels-name"} title={category.name}>{category.name}</h4>
            <p className={"category-labels-count"}>
              {category.count_of_goods}{" "}
              {declOfNum(category.count_of_goods, [
                "товар",
                "товара",
                "товаров",
              ])}
            </p>
          </div>
        ))}
      </div>
    </>
  );
};

export default CategoriesLabels;
