import React from "react";
import {Link} from "react-router-dom";
import "./Header.scss";
import Logo from "../../shared/logo.svg";
import Shop from "../../shared/shop.svg";
import Phone from "../../shared/phone.svg";
import Mail from "../../shared/mail.svg";
import Abstract from "../../shared/abstract.svg";
import Cart from "../../shared/cart.svg";
import { ReactSVG } from "react-svg";
import {Img} from "react-image";

const Header = () => {
  return (
    <header className={"header"}>
      <div className={"main"}>
        <ReactSVG src={Abstract} className={"abstract"} />
        <div className={"header-upper-container container"}>
          <div className={"left-container"}>
            <Link to={"/"} className={"header-icon"}>
              <Img src={Logo} className={"site-logo"}/>
            </Link>
            <Link to={"/"} className={"header-title-container"}>
              <h1 className={"header-title"}>Строитель</h1>
            </Link>
            <Link className={"shop"} to={"/shop"}>
              <ReactSVG src={Shop} className={"shop-icon"} />
              {""}
              <p className={"shop-title"}>Каталог</p>
            </Link>
            <Link className={"cart"} to={"/cart"}>
              <ReactSVG src={Cart} className={"cart-icon"} />
              {""}
              <p className={"cart-title"}>Корзина</p>
            </Link>
          </div>
          <div className={"contact"}>
            <div className={"contact-container"}>
              <ReactSVG src={Phone} className={"contact-container-icon"} />
              <a
                href="tel:+7(47363)-64-6-19"
                className={"contact-container-link"}
              >
                <nobr>+7(47363)-64-6-19</nobr>
              </a>
            </div>
            <div className={"contact-container"}>
              <ReactSVG src={Mail} className={"contact-container-icon"} />
              <a
                href="mailto:santeh-36s@yandex.ru"
                className={"contact-container-link"}
              >
                <nobr>santeh-36s@yandex.ru</nobr>
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
