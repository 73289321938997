import React, {useEffect, useState} from "react";
import "./Footer.scss";
import {Link} from "react-router-dom";
import Logo from "../../shared/logo.svg";
import Phone from "../../shared/phone.svg";
import Mail from "../../shared/mail.svg";
import Abstract from "../../shared/abstract.svg";
import FooterTriangle from "../../shared/footer-triangle.svg";
import { ReactSVG } from "react-svg";
import {Img} from "react-image";

const Footer = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetch("https://santeh-36.ru/api/categories")
        .then((res) => res.json())
        .then(
            (result) => {
              setCategories(result);
            },
            (error) => {
              console.log(error);
            }
        );
  }, []);

  return (
    <footer className={"footer"}>
      <div className={"main-extended footer-container"}>
        <ReactSVG src={FooterTriangle} className={"footer-triangle"}/>
        <ReactSVG src={Abstract} className={"footer-abstract"} />
        <div>
          <div className={"left-container-footer"}>
            <Link to={"/"}>
              <Img src={Logo} className={"site-logo"}/>
            </Link>
            <h1 className={"title"}>Строитель</h1>
          </div>
          <div className={"footer-contact"}>
            <div className={"contact-container"}>
              <ReactSVG src={Phone} className={"contact-container-icon"} />
              <a
                href="tel:+7(47363)-64-6-19"
                className={"contact-container-link"}
              >
                +7(47363)-64-6-19
              </a>
            </div>
            <div className={"contact-container"}>
              <ReactSVG src={Mail} className={"contact-container-icon"} />
              <a
                  href="mailto:santeh-36s@yandex.ru"
                  className={"contact-container-link"}
              >
                santeh-36s@yandex.ru
              </a>
            </div>
            <div className={"contact-container"}>
              <ReactSVG src={Phone} className={"contact-container-icon"} />
              <a
                href="tel:+7(47363)-28-2-92"
                className={"contact-container-link"}
              >
                +7(47363)-28-2-92
              </a>
            </div>
          </div>
        </div>
        <div>
          <h2 className={"category-title"}>Каталог</h2>
          <div className={"category-container"}>
            {categories.map((category, key) => (
              <div key={key} className={"category-item"}>
                <Link to={`/shop/${category._id}`}>{category.name}</Link>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={"copyright"}>Магазин Строитель © 2021</div>
    </footer>
  );
};

export default Footer;
