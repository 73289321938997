import React from "react";
import Header from "../Header/Header";
import { BrowserRouter as Router } from "react-router-dom";
import "./Layout.scss";
import Footer from "../Footer/Footer";
import {ReactSVG} from "react-svg";
import Triangle from "../../shared/triangle.svg";

function Layout(props) {
    return (
        <>
            <Router>
                <ReactSVG src={Triangle} className={"triangle"} />
                <Header />
                <div className={"content-container main"}>
                    {props.children}
                </div>
                <Footer />
            </Router>
        </>
    );
}

export default Layout;
