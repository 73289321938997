import React, {useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import ImageCarousel from "../../components/ImageCarousel/ImageCarousel";
import Search from "../../components/Search/Search";
import "./Product.scss";
import PropsTable from "../../components/PropsTable/PropsTable";
import ProductCard from "../../components/ProductCard/ProductCard";

const Product = ({match}) => {
    const [prod, setProd] = useState();
    const history = useHistory();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        fetch("https://santeh-36.ru/api/goods/" + match.params.id)
            .then((res) => res.json())
            .then(
                (result) => {
                    setProd(result);
                },
                (error) => {
                    console.log(error);
                }
            );
    }, [match.params.id]);

    const handleBack = () => {
        history.goBack();
    };

    return (
        <>
            <Search/>
            <Link className={'prod-back'} to="" onClick={handleBack}>Назад</Link>
            {prod ? (
                <>
                    <h1>{prod.name}</h1>
                    <div className={"product-upper-container"}>
                        {((prod.images && prod.images.length > 0) || prod.cover) ? <ImageCarousel prod={prod}/> : <></>}
                        <ProductCard prod={prod}/>
                    </div>
                    {prod.description && (
                        <div className={"product-text"}>
                            <h2>О товаре</h2>
                            <hr/>
                            {prod.description}
                        </div>
                    )}
                    <PropsTable prod={prod}/>
                </>
            ) : (
                <h1>Такой продукт не найден</h1>
            )}
        </>
    );
};

export default Product;
