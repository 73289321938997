import React, { useEffect } from "react";
import Search from "../../components/Search/Search";
import CategoriesTiles from "../../components/CategoriesTiles/CategoriesTiles";
import Carousel from "../../components/Carousel/Carousel";
import ShopCards from "../../components/ShopCards/ShopCards";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Search />
      <CategoriesTiles />
      <Carousel />
      <h2>Каталог</h2>
      <ShopCards />
    </>
  );
};

export default Home;
