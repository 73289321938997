import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./ShopTiles.scss";
import { ReactSVG } from "react-svg";
import { Img } from "react-image";
import ReactPaginate from "react-paginate";
import Cart from "../../shared/cart-small.svg";
import Check from "../../shared/check-mark.svg";

const ShopTiles = ({ maxCount = 9, params = {} }) => {
  const [customsPerPage] = useState(maxCount);
  const [offset] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [customs, setAllCustoms] = useState([]);
  const [cart, setCart] = useState([]);

  const [goods, setGoods] = useState([]);

  function objToQueryString(obj) {
    const keyValuePairs = [];
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] && obj[key] !== "undefined") {
        if (key === "country") {
          obj["reservedCountry"] = obj[key];
        }
        if (key === "material") {
          obj["reservedMaterial"] = obj[key];
        }
        if (key === "props") {
          obj["reservedProps"] = obj[key];
          for (const propKey in obj[key]) {
            if (obj[key].hasOwnProperty(propKey) && obj[key][propKey]) {
              obj[key][propKey] = Array.isArray(obj[key][propKey])
                  ? obj[key][propKey].join("\\propBreak\\")
                  : obj[key][propKey];
            }
          }
          let props = "";
          for (const propKey in obj[key]) {
            if (obj[key].hasOwnProperty(propKey)) {
              props +=
                  "\\startProp\\" +
                  propKey +
                  "\\startValues\\" +
                  obj[key][propKey];
            }
          }
          keyValuePairs.push(
              encodeURIComponent("props") + "=" + encodeURIComponent(props)
          );
        } else {
          keyValuePairs.push(
              encodeURIComponent(key) +
              "=" +
              encodeURIComponent(
                  Array.isArray(obj[key]) ? obj[key].join("\\\\") : obj[key]
              )
          );
        }
      }
    }
    return keyValuePairs.join("&");
  }

  useEffect(() => {
    if (params) {
      const offset_params = {
        ...params,
        offset: offset - 1,
        limit: customsPerPage,
      };
      const queryString = objToQueryString(offset_params);
      fetch(`https://santeh-36.ru/api/goods/filter-count?${queryString}`)
          .then((res) => res.json())
          .then(
              (count) => {
                fetch(`https://santeh-36.ru/api/goods/filter?${queryString}`)
                    .then((res) => res.json())
                    .then(
                        (result) => {
                          setGoods(result);
                          setAllCustoms(result);
                          setPageCount(Math.ceil(count / customsPerPage));
                        },
                        (error) => {
                          console.log(error);
                        }
                    );
              },
              (error) => {}
          );
    } else {
      fetch(`https://santeh-36.ru/api/goods/count`)
          .then((res) => res.json())
          .then(
              (count) => {
                fetch(`https://santeh-36.ru/api/goods/`)
                    .then((res) => res.json())
                    .then(
                        (result) => {
                          setGoods(result);
                          setAllCustoms(result);
                          setPageCount(Math.ceil(count / customsPerPage));
                        },
                        (error) => {
                          console.log(error);
                        }
                    );
              },
              (error) => {}
          );
    }
  }, [params, customsPerPage, offset]);

  const getAllCustoms = () => {
    const slice = goods.slice(offset - 1, offset - 1 + customsPerPage);

    setAllCustoms(slice);
    setPageCount(Math.ceil(goods?.length / customsPerPage));
  };

  const truncate = (str, n) => {
    return str?.length > n ? str?.substr(0, n - 1) + "..." : str;
  };

  useEffect(() => {
    getAllCustoms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  useEffect(() => {
    if (!sessionStorage.cart) {
      sessionStorage.cart = "[]";
    }
    const cart = JSON.parse(sessionStorage.cart);
    setCart(cart);
  }, []);

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    if (params) {
      const offset_params = {
        ...params,
        offset: selectedPage * customsPerPage,
        limit: customsPerPage,
      };
      const queryString = objToQueryString(offset_params);
      fetch(`https://santeh-36.ru/api/goods/filter-count?${queryString}`)
          .then((res) => res.json())
          .then(
              (count) => {
                fetch(`https://santeh-36.ru/api/goods/filter?${queryString}`)
                    .then((res) => res.json())
                    .then(
                        (result) => {
                          setGoods(result);
                          setAllCustoms(result);
                          setPageCount(Math.ceil(count / customsPerPage));
                        },
                        (error) => {
                          console.log(error);
                        }
                    );
              },
              (error) => {}
          );
    } else {
      fetch(`https://santeh-36.ru/api/goods/count`)
          .then((res) => res.json())
          .then(
              (count) => {
                fetch(`https://santeh-36.ru/api/goods/`)
                    .then((res) => res.json())
                    .then(
                        (result) => {
                          setGoods(result);
                          setAllCustoms(result);
                          setPageCount(Math.ceil(count / customsPerPage));
                        },
                        (error) => {
                          console.log(error);
                        }
                    );
              },
              (error) => {}
          );
    }
    window.scrollTo(0, 0);
  };

  const addToCart = (id) => {
    if (!sessionStorage.cart) {
      sessionStorage.cart = "[]";
    }
    const cart = JSON.parse(sessionStorage.cart);
    if (!cart.includes(id)) {
      cart.push(id);
    }
    sessionStorage.cart = JSON.stringify(cart);
    setCart(cart);
  };


  return (
    <>
      {(!customs || customs?.length === 0) && (
        <h3>Отсутствуют товары в данной категории</h3>
      )}
      <div className={"shop-tile-container"}>
        {customs.map((custom, key) => {
          return (
            <div className={"shop-tile"} key={key}>
              <Link className={"shop-tile-item"} to={`/product/${custom._id}`}>
                <div className={"shop-tile-image-container"}>
                  {custom.cover && <Img
                    src={`data:image/jpeg;base64,${custom.cover}`}
                    className={"shop-tile-image"}
                  />}
                </div>
                <div className={"shop-tile-description"}>
                  <p className={"shop-tile-title"}>
                    {truncate(custom.name, 50)}
                  </p>
                  <div>
                    {custom.country && (
                      <p className={"shop-tile-text"}>
                        Страна: {truncate(custom.country, 50)}
                      </p>
                    )}
                    {custom.size && (
                      <p className={"shop-tile-text"}>
                        Размер: {custom.size.width}
                        {custom.size.height && "x" + custom.size.height}
                        {custom.size.depth && "x" + custom.size.depth}
                        {custom.size.size_type && " " + custom.size.size_type}
                      </p>
                    )}
                    {custom.material && (
                      <p className={"shop-tile-text"}>
                        Материал: {truncate(custom.material, 35)}
                      </p>
                    )}
                    {custom.bar_code && (
                      <p className={"shop-tile-text"}>
                        Артикул: {truncate(custom.bar_code, 35)}
                      </p>
                    )}
                    {custom.props?.slice(0, 3).map((prop, key) => (
                      <p className={"shop-tile-text"} key={key}>
                        {truncate(prop.name, 35)}: {truncate(prop.value, 35)}
                      </p>
                    ))}
                  </div>
                  <p className={"shop-tile-price"}>
                    {custom.price} руб./{custom.price_type}
                  </p>
                </div>
              </Link>
              {!cart?.includes(custom._id) ? (
                  <button
                      onClick={() => addToCart(custom._id)}
                      className={"shop-tile-cart-container"}
                  >
                    <p>В корзину</p>
                    <ReactSVG src={Cart} className={"shop-tile-cart"} />
                  </button>
              ) : (
                  <button
                      className={"shop-tile-bought"}
                  >
                    <p>В корзине</p>
                    <ReactSVG src={Check} className={"shop-tile-cart"} />
                  </button>
              )}
            </div>
          );
        })}
      </div>
      {customs?.length > 0 && pageCount > 1 && (
        <div className={"pagination-container"}>
          <ReactPaginate
            nextLabel={">"}
            previousLabel={"<"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            disabledClassName={"pagination-hidden-button"}
            pageCount={pageCount}
            onPageChange={handlePageClick}
            marginPagesDisplayed={2}
            pageRangeDisplayed={4}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      )}
    </>
  );
};

export default ShopTiles;
