import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import Shop from "./pages/Shop/Shop";
import Home from "./pages/Home/Home";
import "./App.scss";
import Layout from "./components/Layout/Layout";
import Product from "./pages/Product/Product";
import Cart from "./pages/Cart/Cart";
import Login from "./pages/Login/Login";
import AdminCategory from "./pages/Admin/Categories/Category";
import AdminGoods from "./pages/Admin/Goods/Goods";
import AdminGood from "./pages/Admin/Good/Good";

function App() {
  return (
    <>
      <Router>
        <Layout>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/shop/:id" component={Shop} />
            <Route path="/shop/" component={Shop} />
            <Route path="/cart">
              <Cart />
            </Route>
            <Route path="/admin/categories/:id" component={AdminGoods} />
            <Route path="/admin/goods/:id?/:category?" component={AdminGood} />
            <Route path="/admin/categories">
              <AdminCategory />
            </Route>
            <Route path="/admin">
              <AdminCategory />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/product/:id" component={Product} />
          </Switch>
        </Layout>
      </Router>
    </>
  );
}

export default App;
