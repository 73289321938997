import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import "./SidePanel.scss";
import { useMediaQuery } from "react-responsive";
// import { filters } from "../../placeholder/placeholder";
import { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import { ReactSVG } from "react-svg";
import MenuIcon from "../../shared/menu_icon.svg";

const SidePanel = ({ params, setParams, owner }) => {
  const [min, setMin] = useState(params.price_lower || 10);
  const [max, setMax] = useState(params.price_upper || 50000);
  const [maxPossible, setMaxPossible] = useState(50000);
  const history = useHistory();
  const location = useLocation();
  const [reservedProps, setReservedProps] = useState([]);
  const [reservedMaterial, setReservedMaterial] = useState([]);
  const [reservedCountry, setReservedCountry] = useState([]);
  const [countries, setCountries] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [props, setProps] = useState([]);
  const [showMenu, setShowMenu] = useState(
    useMediaQuery({
      query: "(min-width: 1199px)",
    })
  );

  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    const newData = [];
    const newProps = [];
    for (const key in data) {
      if (data.hasOwnProperty(key) && data[key]) {
        if (typeof key === "string" && key.startsWith("\\\\")) {
          const parsedKey = key.substr(2);
          if (!newProps[parsedKey]) {
            newProps[parsedKey] = [];
          }
          newProps[parsedKey].push(data[key]);
        } else {
          if (!newData[key]) {
            newData[key] = [];
          }
          newData[key].push(data[key]);
        }
      }
      window.scrollTo(0, 0);
    }

    const props = {};
    for (const propKey in newProps) {
      if (newProps.hasOwnProperty(propKey)) {
        if (newProps[propKey][0].length > 0) {
          props[propKey] = newProps[propKey][0];
        }
      }
    }

    const state = {};
    for (const propKey in newData) {
      if (newData.hasOwnProperty(propKey) && newData[propKey][0].length > 0) {
        state[propKey] = newData[propKey][0];
      }
    }

    const newState = Object.assign(
      {},
      state,
      { props: props },
      { reservedProps: props },
      { reservedCountry: data.country },
      { reservedMaterial: data.material },
      params.owner_id &&
        params.owner_id !== "undefined" && { owner_id: params.owner_id },
      params.name && { name: params.name },
      params.sortByPriceDesc && { sortByPriceDesc: params.sortByPriceDesc },
      params.sortByPrice && { sortByPrice: params.sortByPrice },
      { price_lower: min },
      { price_upper: max }
    );
    history.push({
      state: newState,
    });
  };

  useEffect(() => {
    const resProps = location?.state?.reservedProps;
    const tmp = {};
    if (resProps) {
      for (const key in resProps) {
        if (resProps.hasOwnProperty(key)) {
          if (resProps[key].includes("\\propBreak\\")) {
            tmp[key] = resProps[key].split("\\propBreak\\");
          } else {
            tmp[key] = resProps[key];
          }
        }
      }
    }
    setReservedProps(tmp);
    const resMaterial = location?.state?.reservedMaterial;
    setReservedMaterial(resMaterial);
    const resCountry = location?.state?.reservedCountry;
    setReservedCountry(resCountry);
  }, [location]);

  useEffect(() => {
    fetch(`https://santeh-36.ru/api/goods/filters/` + (owner ? owner : ''))
      .then((res) => res.json())
      .then(
        (result) => {
          let countriesFilter = new Set();
          let materialsFilter = new Set();
          let propsFilter = [];
          for (let elem of result) {
            countriesFilter.add(elem.country);
            materialsFilter.add(elem.material);
            for (let prop of elem.props) {
              if (!propsFilter[prop.name]) {
                propsFilter[prop.name] = new Set();
              }
              propsFilter[prop.name].add(prop.value);
            }
          }
          setCountries([...countriesFilter]);
          setMaterials([...materialsFilter]);
          setProps(
            Object.entries(propsFilter).map((e) => ({
              name: e[0],
              value: [...e[1]],
            }))
          );
        },
        (error) => {
          console.log(error);
        }
      );

    fetch(`https://santeh-36.ru/api/goods/max-price`)
      .then((res) => res.json())
      .then(
        (result) => {
          setMaxPossible(result);
        },
        (error) => {
          console.log(error);
        }
      );
  }, [owner]);

  const resetFilters = () => {
    const newState = Object.assign(
      {},
      { reservedProps: null },
      { reservedCountry: null },
      { reservedMaterial: null }
    );
    history.push({
      state: newState,
    });
    window?.location.reload();
  };

  const toggleMenu = () => {
    setShowMenu((prev) => !prev);
  };

  return (
    <div className={"side-panel-block"}>
      <button
        className={`menu-icon-container ${
          !showMenu && "menu-icon-container-extended"
        }`}
        onClick={() => toggleMenu()}
      >
        {!showMenu && "Фильтры"}
        {""}
        <ReactSVG src={MenuIcon} className={"menu-icon"} />
      </button>
      {showMenu && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={"side-panel-container"}>
            <div className={"side-panel-filter"}>
              {countries && (countries?.length > 0) && (countries[0]) && <h3>Страна</h3>}
              {countries?.map((value, key) => (
                <React.Fragment key={key}>
                  {value && (
                    <div className={"side-panel-input"} key={key}>
                      <label>
                        <input
                          type={"checkbox"}
                          {...register("country")}
                          value={value}
                          defaultChecked={
                            Array.isArray(reservedCountry)
                              ? reservedCountry.includes(value)
                              : reservedCountry === value
                          }
                        />{" "}
                        {value}
                      </label>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
            <div className={"side-panel-filter"}>
              {materials && (materials?.length > 0) && (materials[0]) && <h3>Материал</h3>}
              {materials?.map((value, key) => (
                <React.Fragment key={key}>
                  {value && (
                    <div className={"side-panel-input"} key={key}>
                      <label>
                        <input
                          type={"checkbox"}
                          {...register("material")}
                          value={value}
                          defaultChecked={
                            Array.isArray(reservedMaterial)
                              ? reservedMaterial.includes(value)
                              : reservedMaterial === value
                          }
                        />{" "}
                        {value}
                      </label>
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
            {props.map((prop, key) => {
              return (
                <div className={"side-panel-filter"} key={key}>
                  <h3>{prop.name}</h3>
                  {prop?.value?.map((value, key) => {
                    return (
                      <React.Fragment key={key}>
                        {value && (
                          <div className={"side-panel-input"} key={key}>
                            <label>
                              <input
                                type={"checkbox"}
                                {...register("\\\\" + prop.name)}
                                value={value}
                                defaultChecked={
                                  Array.isArray(reservedProps[prop.name])
                                    ? reservedProps[prop.name].includes(value)
                                    : reservedProps[prop.name] === value
                                }
                              />{" "}
                              {value}
                            </label>
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              );
            })}
            <h3>Цена</h3>
            <div className={"side-panel-range"}>
              от{" "}
              <input
                name={"min"}
                value={min}
                onChange={(e) => {
                  setMin(e.target.value);
                }}
              />
              до{" "}
              <input
                name={"max"}
                value={max}
                onChange={(e) => {
                  setMax(e.target.value);
                }}
              />
            </div>
            <Range
              min={1}
              max={maxPossible}
              value={[min, max]}
              className={"side-panel-slider"}
              onChange={(e) => {
                if (e[0] < e[1]) {
                  setMin(e[0]);
                  setMax(e[1]);
                } else {
                  setMin(e[1]);
                  setMax(e[0]);
                }
              }}
            />
            <div className={"side-panel-button-container"}>
              <button className={"side-panel-submit-button"} type={"submit"}>
                Применить
              </button>
              <button
                className={"side-panel-reset-button"}
                type={"button"}
                onClick={() => resetFilters()}
              >
                Сбросить
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default SidePanel;
