import React, { useState } from "react";
import "./Login.scss";
import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [error, setError] = useState("");
  // eslint-disable-next-line
  const [cookies, setCookie] = useCookies(['token']);
  const history = useHistory();
  const onSubmit = (data) => {
    fetch("https://santeh-36.ru/api/admin", {
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      },
      method: "POST",
    })
      .then((res) => {
        return res.json();
      })
      .then(
        (result) => {
          setCookie("token", result);
          history.push("/admin");
        },
        (error) => {
          console.log(error);
          setError("Ошибка входа, проверьте логин или пароль");
        }
      );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={"login-form-container"}>
        <label>
          Логин
          <input {...register("login", { required: true })} />
        </label>
        {errors.login && (
          <p className={"login-form-error"}>Необходимо ввести логин.</p>
        )}
        <label>
          Пароль
          <input {...register("password", { required: true })} />
        </label>
        {errors.password && (
          <p className={"login-form-error"}>Необходимо ввести логин..</p>
        )}
        <input type="submit" />
        {<p className={"login-form-error"}>{error}</p>}
      </div>
    </form>
  );
};

export default Login;
