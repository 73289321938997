import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import "./CategoriesTiles.scss";

const SubCategoriesTiles = ({parentId}) => {
    const [categories, setCategories] = useState([]);
    const [parent, setParent] = useState([]);

    function declOfNum(n, titles) {
        return titles[
            n % 10 === 1 && n % 100 !== 11
                ? 0
                : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
                ? 1
                : 2
            ];
    }

    useEffect(() => {
        fetch("https://santeh-36.ru/api/categories/subcategories/" + parentId)
            .then((res) => res.json())
            .then(
                (result) => {
                    setCategories(result);
                },
                (error) => {
                    console.log(error);
                    setCategories(null);
                }
            );

        fetch("https://santeh-36.ru/api/categories/parent/" + parentId)
            .then((res) => res.json())
            .then(
                (result) => {
                    setParent(result);
                },
                (error) => {
                    console.log(error);
                    setParent(null);
                }
            );
    }, [parentId]);
    return (
        categories.length > 0 ?
        <>
            <h2>Подкатегории</h2>
            <div className="subcategories-tiles-container">
                {categories.map((category, key) => (
                    <Link
                        className={"subcategory-tiles-item"}
                        key={key}
                        to={`/shop/${category._id}`}
                    >
                        <h4 className={"category-tiles-name"}>{category.name}</h4>
                        <p className={"category-tiles-count"}>
                            {category.count_of_goods}{" "}
                            {declOfNum(category.count_of_goods, ["товар", "товара", "товаров"])}
                        </p>
                    </Link>
                ))}
            </div>
            {parent?._id ? <Link to={'/shop/' + parent?._id} className="subcategories-back">Назад к {parent?.name}</Link> : <div className={"subcategories-back"} /> }
        </>
            :
            <>{parent?._id ? <Link to={'/shop/' + parent?._id} className="subcategories-back">Назад к {parent?.name}</Link> : <div className={"subcategories-back"} /> }</>
    );
};

export default SubCategoriesTiles;
