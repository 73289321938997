import React, { useState } from "react";
import "./Category.scss";
import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";

const AdminCategory = () => {
  const history = useHistory();
  const [cookies] = useCookies(["token"]);
  const [categories, setCategories] = useState([]);
  const [editableId, setEditableId] = useState(-1);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
  } = useForm();
  const onSubmit = (data) => {
    fetch("https://santeh-36.ru/api/categories", {
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + cookies["token"],
      },
      method: "POST",
    })
      .then((res) => {
        return res.json();
      })
      .then(
        (result) => {
          window.location.reload();
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleSetInEditMode = (_id) => {
    setEditableId(_id);
  };

  const onUpdateCategory = (data) => {
    fetch("https://santeh-36.ru/api/categories", {
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + cookies["token"],
      },
      method: "PUT",
    })
      .then((res) => {
        return res.json();
      })
      .then(
        (result) => {
          window.location.reload();
        },
        (error) => {
          console.log(error);
        }
      );
  };

  useEffect(() => {
    fetch("https://santeh-36.ru/api/admin", {
      headers: {
        Authorization: "Bearer " + cookies["token"],
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (!result) {
            history.push("/login");
          }
        },
        (error) => {
          history.push("/login");
        }
      );
    fetch("https://santeh-36.ru/api/categories")
      .then((res) => res.json())
      .then(
        (result) => {
          setCategories(result);
        },
        (error) => {
          console.log(error);
        }
      );
  }, [history, cookies]);

  function declOfNum(n, titles) {
    return titles[
      n % 10 === 1 && n % 100 !== 11
        ? 0
        : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
        ? 1
        : 2
    ];
  }

  const handleDeleteCategory = (_id, cnt) => {
    if (cnt === 0) {
      fetch("https://santeh-36.ru/api/categories/" + _id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + cookies["token"],
        },
        method: "Delete",
      })
        .then((res) => {
          return res.json();
        })
        .then(
          (result) => {
            fetch("https://santeh-36.ru/api/categories" + _id)
              .then((res) => res.json())
              .then(
                (result) => {
                  setCategories(result);
                },
                (error) => {
                  console.log(error);
                }
              );
          },
          (error) => {
            console.log(error);
          }
        );
    }
    else {
      window.alert('Невозможно удалить категорию с товарами!');
    }
  };

  return (
    <>
      <h1>Добавление новой категории</h1>
      <form onSubmit={handleSubmit(onSubmit)} key={"add_category"}>
        <div className={"login-form-container"}>
          <label>
            Название категории
            <input {...register("name", { required: true })} />
          </label>
          {errors.name && (
            <p className={"login-form-error"}>
              Необходимо ввести название категории.
            </p>
          )}
          <input type="submit" />
        </div>
      </form>
      <h1>Редактирование категорий</h1>
      <div className="admin-categories-labels-container">
        {[
          {
            _id: "",
            name: "Все товары",
            count_of_goods: categories.reduce(
              (cnt, cat) => cnt + (cat.count_of_goods || 0),
              0
            ),
          },
          ...categories,
        ].map((category, key) => (
          <React.Fragment key={key}>
            {category.name !== "Все товары" && (
              <div className={"admin-categories-labels-item"}>
                {category._id === editableId ? (
                  <form onSubmit={handleSubmit2(onUpdateCategory)} key={key}>
                    <label>
                      {category.name}
                      <input {...register2("name", { required: true })} />
                    </label>
                    {errors2.name && (
                      <p className={"login-form-error"}>
                        Необходимо ввести название категории.
                      </p>
                    )}
                    <input
                      {...register2("id", { required: true })}
                      defaultValue={category._id}
                      type={"hidden"}
                    />
                    <input
                      {...register2("count_of_goods", { required: true })}
                      defaultValue={category.count_of_goods}
                      type={"hidden"}
                    />
                    <p className={"category-labels-count"}>
                      {category.count_of_goods}{" "}
                      {declOfNum(category.count_of_goods, [
                        "товар",
                        "товара",
                        "товаров",
                      ])}
                    </p>
                    <input type="submit" />
                    <button
                      type={"button"}
                      onClick={() => {
                        handleSetInEditMode(-1);
                      }}
                    >
                      Отменить редактирование
                    </button>
                    <button
                      type={"button"}
                      onClick={() => {
                        handleDeleteCategory(category._id, category.count_of_goods);
                      }}
                    >
                      Удалить
                    </button>
                    <Link to={"/admin/categories/" + category._id}>
                      <button>К товарам в данной категории</button>
                    </Link>
                  </form>
                ) : (
                  <>
                    <h4 className={"category-labels-name"}>{category.name}</h4>
                    <p className={"category-labels-count"}>
                      {category.count_of_goods}{" "}
                      {declOfNum(category.count_of_goods, [
                        "товар",
                        "товара",
                        "товаров",
                      ])}
                    </p>
                    <button
                      onClick={() => {
                        handleSetInEditMode(category._id);
                      }}
                    >
                      Редактировать
                    </button>
                    <button
                      onClick={() => {
                        handleDeleteCategory(category._id, category.count_of_goods);
                      }}
                    >
                      Удалить
                    </button>
                    <Link to={"/admin/categories/" + category._id}>
                      <button>К товарам в данной категории</button>
                    </Link>
                  </>
                )}
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default AdminCategory;
